// src/contexts/AuthContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import {UserData} from '../types'

interface AuthContextType {
    isAuthenticated: boolean;
    username: string | null;
    login: (userData: any) => void;
    logout: () => void;
    userData: UserData;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [username, setUsername] = useState<string | null>(null);
    const [userData, setUserData] = useState<any>({});

    useEffect(() => {
        // Check localStorage for token and user info
        const token = localStorage.getItem('token');
        const storedUsername = localStorage.getItem('username');
        if (token) {
            setIsAuthenticated(true);
            setUsername(storedUsername);
            const decodedToken = jwtDecode(token);
            setUserData(decodedToken);
        }
    }, []);

    const login = (userData: any) => {
        setIsAuthenticated(true);
        setUserData(userData);
        setUsername(userData.sub ?? '');
        localStorage.setItem('username', userData.sub ?? ''); // Save username to localStorage
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUsername(null);
        localStorage.removeItem('token');
        localStorage.removeItem('username'); // Remove username from localStorage
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, username, login, logout, userData }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
