import React, { useState } from 'react';
import { RegisterDto, UserData } from '../../types';
import { register } from '../../services/authService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useNotification } from '../../contexts/NotificationContext';
import { parseErrorResponse, NetErrorResponse } from '../../utility/parseErrorResponse';
import axios, { AxiosError } from 'axios';
import { jwtDecode } from "jwt-decode";

const RegisterForm: React.FC = () => {
    const [user, setUser] = useState<RegisterDto>({ username: '', password: '', password2: '' });
    const navigate = useNavigate();
    const { login: authLogin } = useAuth();
    const [error, setError] = useState<string | null>(null);
    const { showNotification, showModal } = useNotification();
    const [errorResponse, setErrorResponse] = useState<NetErrorResponse>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await register(user);
            localStorage.setItem('token', response.token);
            const decodedToken = jwtDecode(response.token);
            authLogin(decodedToken as UserData);
            showNotification('Sikeres regisztráció!');
            setErrorResponse({});
            navigate('/'); // Navigate to home page or dashboard
        }catch (error: any) {
            if (axios.isAxiosError(error) && error.response?.status === 500) {
              showModal(error.response.data.message);
            }
            else if(axios.isAxiosError(error) && error.response?.status === 400){
              const parsedErrors = parseErrorResponse(error as AxiosError);
              setErrorResponse(parsedErrors);
              showModal(parsedErrors.Message ?? '');
            }
            else{
              alert('An unexpected error occurred');
              console.log(error);
            }
        }            
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Felhasználónév</label>
                <input type="text" className="form-control" name="username" value={user.username} onChange={handleChange} required />
                {errorResponse.Errors?.Username && errorResponse.Errors.Username.map((error, index) => (
                    <span key={index} className='text-danger'>{error}</span>
                ))}
            </div>
            <div className="form-group">
                <label>Jelszó</label>
                <input type="password" className="form-control" name="password" value={user.password} onChange={handleChange} required />
                {errorResponse.Errors?.Password && errorResponse.Errors.Password.map((error, index) => (
                    <span key={index} className='text-danger'>{error}</span>
                ))}
            </div>
            <div className="form-group">
                <label>Jelszó mégegyszer</label>
                <input type="password" className="form-control" name="password2" value={user.password2} onChange={handleChange} required />
                {errorResponse.Errors?.Password2 && errorResponse.Errors.Password2.map((error, index) => (
                    <span key={index} className='text-danger'>{error}</span>
                ))}
            </div>
            <div className="form-group">
                <label>Teljes név</label>
                <input type="text" className="form-control" name="fullName" value={user.fullName} onChange={handleChange} />
                {errorResponse.Errors?.FullName && errorResponse.Errors.FullName.map((error, index) => (
                    <span key={index} className='text-danger'>{error}</span>
                ))}
            </div>
            <br></br>
            <button type="submit" className="btn btn-primary">Regisztráció</button>
        </form>
    );
};

export default RegisterForm;