
// src/pages/RegisterPage.tsx
import React from 'react';
import RegisterForm from '../components/auth/RegisterForm';

const RegisterPage: React.FC = () => {
    return (
        <div>
            <h2>Regisztráció</h2>
            <RegisterForm />
        </div>
    );
};

export default RegisterPage;
