import React, { useEffect, useState } from 'react';
import { getRankList, saveTip } from '../services/gameService';
import { RankDto } from '../types';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { useNotification } from '../contexts/NotificationContext';


const RankListPage: React.FC = () => {
  const { competitionId } = useParams<{ competitionId: string }>();
  const [rankList, setRankList] = useState<RankDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { showModal } = useNotification();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getRankList(parseInt(competitionId ?? '', 10));
        setRankList(data);
      } catch (error: any) {
        if (axios.isAxiosError(error) && error.response?.status === 500) {
            showModal(error.response.data.message);
        }else{
          alert('An unexpected error occurred');
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [competitionId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <Link to="/competition" className="breadcrumb-item">Versenyek</Link>
        <li className="breadcrumb-item active" aria-current="page">Ranglista</li>
      </ol>
    </nav>
    
    <h2>Ranglista</h2>
    <Link to="/competition" className='btn btn-secondary'>Vissza</Link>
    <p></p>
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Rank</th>
          <th>Játékos</th>
          <th>Pontok</th>
        </tr>
      </thead>
      <tbody>
        {rankList.map((rankItem) => (
          <tr key={rankItem.rank}>
            <td>{rankItem.rank}</td>
            <td>{rankItem.player}</td>
            <td>{rankItem.points}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </>
  );
};

export default RankListPage;
