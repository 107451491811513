// MatchModal.tsx
import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { changePassword } from '../../services/authService';
import { useTypes } from '../../contexts/CommonContext';
import { ChangePasswordDto } from '../../types';
import { Modal, Button } from 'react-bootstrap';
import { AxiosError } from 'axios';
import  axios from 'axios';
import { parseErrorResponse, NetErrorResponse } from '../../utility/parseErrorResponse';
import { useNotification } from '../../contexts/NotificationContext';

interface ModalProps {
    onSave: () => void;
    onClose: () => void;
    showFormModal: boolean;
}
  


const ChangePasswordModal: React.FC<ModalProps> = ({  onSave, onClose }) => {

    const [errorResponse, setErrorResponse] = useState<NetErrorResponse>({});
    const { showNotification, showModal } = useNotification();
    const [formData, setFormData] = useState<ChangePasswordDto>({oldPassword: '', password: '', password2: ''});

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
            setFormData((prevData) => ({...prevData, [name]: value}));
        };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await changePassword(formData);
            onSave();
            setErrorResponse({});
            showNotification('Sikeres mentés!');        
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response?.status === 500) {
                showModal(error.response.data.message);
            }
            else if(axios.isAxiosError(error) && error.response?.status === 400){
                const parsedErrors = parseErrorResponse(error as AxiosError);
                setErrorResponse(parsedErrors);
                showModal(parsedErrors.Message ?? '');
            }
            else{
                alert('An unexpected error occurred');
                console.log(error);
            }
        }
    };

    return (
        <Modal show={true} onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Jelszó változtatás</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="oldPassword" className="form-label">Régi jelszó: <span className='text-danger'>*</span></label>
                        <input type="password" name="oldPassword" id="oldPassword" value={formData.oldPassword} onChange={handleChange} className="form-control" />
                        <ul className='list-style-none'>
                            {errorResponse.Errors?.OldPassword && errorResponse.Errors.OldPassword.map((error, index) => (
                                <li key={index} className='text-danger'>{error}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="v" className="form-label">Új jelszó: <span className='text-danger'>*</span></label>
                        <input type="password" name="password" value={formData.password} onChange={handleChange} className="form-control" />
                        <ul className='list-style-none'>
                            {errorResponse.Errors?.Password && errorResponse.Errors.Password.map((error, index) => (
                                <li key={index} className='text-danger'>{error}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password2" className="form-label">Új jelszó még egyszer: <span className='text-danger'>*</span></label>
                        <input type="password" name="password2" value={formData.password2} onChange={handleChange} className="form-control" />
                        <ul className='list-style-none'>
                            {errorResponse.Errors?.Password2 && errorResponse.Errors.Password2.map((error, index) => (
                                <span key={index} className='text-danger'>{error}</span>
                            ))}
                        </ul>
                    </div>
                    <div className="mb-3">
                        <button type="submit" className='btn btn-primary'>Mentés</button><span></span>
                        <button type="button" className='btn btn-secodary' onClick={onClose}> Mégse</button><span></span>
                    </div>
                </form>
            </Modal.Body>
        </Modal>   
    );
};

export default ChangePasswordModal;
