// src/pages/LoginPage.tsx
import React from 'react';
import LoginForm from '../components/auth/LoginForm';

const LoginPage: React.FC = () => {
    return (
        <div>
            <h2>Bejelentkezés</h2>
            <LoginForm />
        </div>
    );
};

export default LoginPage;
