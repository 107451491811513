// src/services/authService.ts
import axios from 'axios';
import { SystemInfoDto } from '../types';

const API_URL = process.env.REACT_APP_API_SERVER_URL+'/api/system'; // Az API végpontod


export const getSystemInfo = async (): Promise<SystemInfoDto> => {
    console.log(API_URL);
    const response = await axios.get<SystemInfoDto>(`${API_URL}/getSystemInfo`);
    return response.data;
};