export interface HasIdAndSeqNo {
    id: number;
    seqNo: number;
}

export function fillSeqNo<T extends HasIdAndSeqNo>(dtos: T[]): T[] {
    let seqCounter = 1;

    return dtos.map(dto => {
        return { ...dto, seqNo: seqCounter++ };
    });
}