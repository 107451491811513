import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Notification {
    message: string;
}

interface Modal {
    message: string;
}

interface NotificationContextType {
    notification: Notification | null;
    modal: Modal | null;
    showNotification: (message: string) => void;
    hideNotification: () => void;
    showModal: (message: string) => void;
    hideModal: () => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [notification, setNotification] = useState<Notification | null>(null);
    const [modal, setModal] = useState<Modal | null>(null);

    const showNotification = (message: string) => {
        if(message){
            setNotification({message});
            console.log('notification: '+message);
        }
        else{
            console.log('toast message is null')
        }
    };

    const hideNotification = () => {
        setNotification(null);
    };

    const showModal = (message: string | null) => {
        if(message){
            setModal({message});
            console.log('modal: '+message);
        }
        else{
            console.log('modal message is null')
        }
    };

    const hideModal = () => {
        setModal(null);
    };

    return (
        <NotificationContext.Provider value={{ notification, modal, showNotification, hideNotification, showModal, hideModal }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = (): NotificationContextType => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};
