// src/services/authService.ts
import axios from 'axios';
import { UserDto, LoginDto, RegisterDto, ChangePasswordDto, AuthResponse } from '../types';

const API_URL = process.env.REACT_APP_API_SERVER_URL+'/api/auth'; // Az API végpontod

export const login = async (user: LoginDto): Promise<AuthResponse> => {
    const response = await axios.post<AuthResponse>(`${API_URL}/login`, user);
    return response.data;
};

export const register = async (user: RegisterDto): Promise<AuthResponse> => {
    const response = await axios.post<AuthResponse>(`${API_URL}/register`, user);
    return response.data;
};

export const getUserDetails = async (): Promise<UserDto> => {
    const response = await axios.get<UserDto>(`${API_URL}/getUserDetails`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }});
    return response.data;
};

export const updateUserDetails = async (user: UserDto): Promise<any> => {
    const response = await axios.put<any>(`${API_URL}/updateUserDetails`, user, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }});
    return response.data;
};

export const changePassword = async (user: ChangePasswordDto): Promise<any> => {
    const response = await axios.put<any>(`${API_URL}/changePassword`, user, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }});
    return response.data;
};
