import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip, Toast } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './MatchPage.css';
import { getTipList, saveTip } from '../services/gameService';
import { TipDto } from '../types';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import { parseErrorResponse, NetErrorResponse, Errors } from '../utility/parseErrorResponse';
import { fillSeqNo, HasIdAndSeqNo } from '../utility/fillSeqNo';
import { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import { useNotification } from '../contexts/NotificationContext';
import './TipPage.css';

const TipPage: React.FC = () => {
  const { competitionId } = useParams<{ competitionId: string }>();
  const [tips, setTips] = useState<TipDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [validationMessages, setValidationMessages] = useState<{ [key: number]: string[] }>({});
  const { showNotification, showModal } = useNotification();
  const [originalTips, setOriginalTips] = useState<TipDto[]>([]);

  useEffect(() => {
    fetchData();
  }, [competitionId]);

  const fetchData = async () => {
    try {
      const data = await getTipList(parseInt(competitionId ?? '', 10));
      const updatedDtos = fillSeqNo(data);
      setTips(updatedDtos);
      setOriginalTips(updatedDtos);
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error));
    } finally {
      setLoading(false);
    }
  };

  const handleBlur = async (item: TipDto) => {
    try {
      const originalItem = originalTips.find(tip => tip.id === item.id);
      if (originalItem && originalItem.hint === item.hint) {
        return; // Do nothing if hint hasn't changed
      }

      await saveTip(item);
      showNotification('Sikeres mentés!');
      setValidationMessages((prevMessages) => ({ ...prevMessages, [item.seqNo]: [] }));
      setOriginalTips((prevTips) => prevTips.map(tip => tip.id === item.id ? item : tip));
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status === 500) {
        showModal(error.response.data.message);
      } else if (axios.isAxiosError(error) && error.response?.status === 400) {
        const parsedErrors = parseErrorResponse(error as AxiosError);
        showModal(parsedErrors.Message ?? '');
        const messages: string[] = [];
        for (const key in parsedErrors.Errors) {
          if (parsedErrors.Errors.hasOwnProperty(key)) {
            messages.push(...parsedErrors.Errors[key].map((msg: string) => `${key}: ${msg}`));
          }
        }
        setValidationMessages((prevMessages) => ({ ...prevMessages, [item.seqNo]: messages }));
      } else {
        alert('An unexpected error occurred');
        console.log(error);
      }
    } finally {
      setTips([...tips]);
    }
  };

  const handleChange = (id: number, seqNo: number, hint: string) => {
    const newItems = tips.map(item => (item.id !== 0 && item.id === id) || (item.id === 0 && item.seqNo === seqNo) ? { ...item, hint } : item);
    setTips(newItems);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>, item: TipDto) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleBlur(item);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <Link to="/competition" className="breadcrumb-item">Versenyek</Link>
          <li className="breadcrumb-item active" aria-current="page">Tippek</li>
        </ol>
      </nav>

      <Row className="mt-3">
        <Col>
          <h2>Tippek</h2>
          <Link to="/competition" className='btn btn-secondary'>Vissza</Link>
          <Row>
            <Col xs={4}>
              <h4>Megnevezés</h4>
            </Col>
            <Col xs={3}>
              <h4>Időpont</h4>
            </Col>
            <Col xs={3}>
              <h4>Tipp</h4>
            </Col>
            <Col xs={1}>
              <h4>Eredmény</h4>
            </Col>
            <Col xs={1}></Col>
          </Row>
          {tips.map(item => (
            <Row key={item.id} className="align-items-center mb-2">
              <input type="hidden" name="resultId" value={item.resultId} />
              <Col xs={4}>
                {item.name}
              </Col>
              <Col xs={3}>
                {format(item.startTime, 'MMMM. dd. HH:mm', { locale: hu })}
              </Col>
              {new Date(item.startTime) > new Date() && (
                <Col xs={3}>
                  <Form.Control
                    type="text"
                    value={item.hint}
                    onChange={e => handleChange(item.id, item.seqNo, e.target.value)}
                    onBlur={() => handleBlur(item)}
                    onKeyDown={(e) => handleKeyDown(e, item)}
                  />
                </Col>
              )}
              {new Date(item.startTime) < new Date() && (
                <Col xs={3}>
                  <input type="hidden" name="hint" value={item.hint} />
                  <span>{item.hint}</span>
                </Col>
              )}
              <Col xs={1}>
                {item.result}
              </Col>
              <Col xs={1}>
                {validationMessages[item.seqNo] && validationMessages[item.seqNo].length > 0 && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${item.seqNo}`} className="custom-tooltip">
                        <ul>
                          {validationMessages[item.seqNo].map((message, index) => (
                            <li key={index} className="text-danger">{message}</li>
                          ))}
                        </ul>
                      </Tooltip>
                    }
                  >
                    <span className="text-danger">⚠️</span>
                  </OverlayTrigger>
                )}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default TipPage;
