// src/pages/WeatherPage.tsx
import React, { useEffect, useState } from 'react';

const RulePage: React.FC = () => {
    
    return (
        <div>
            <h2>Játékszabály</h2>
            <p className="lead">A játék célja az Foci bajnokságok minden mérkőzésének lehető legpontosabb végeredményét megtippelni.</p>
            
            <p>A meccsek eredményét a következő formátumban kell megadni pl.: 1-2. Tippelni az eredményekben beállított időpontig lehetséges, ez általában a mecs kezdésének időpntja. A tippeket rögzíteni a Tippek menüpontban lehet. Bárki rögzítheti a meccsek eredményeit.</p>

            <div>
            Pontos végeredmény eltalálása 10p<br />
            Gólkülönbség eltalálása csak akkor ha a kimenetel is stimmel 6p<br />
            Mérkőzés kimenetelének eltalálása 4p<br />
            Az egyik csapat góljainak eltalálása 3p<br />
            Összes szerzett gól eltalálása 2p<br />
            Gólkülönbség eltalálása fordított eredménnyel 1p<br />
            +15 pont járt a torna gólkirályának eltalálásáért<br />
            +15 pont pedig a végső győztes eltalálásáért jár<br />
            </div>

        </div>
    );
};

export default RulePage;
