// MatchModal.tsx
import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { saveMatch } from '../../services/gameService';
import { useTypes } from '../../contexts/CommonContext';
import { MatchDto } from '../../types';
import { Modal, Button } from 'react-bootstrap';
import { AxiosError } from 'axios';
import  axios from 'axios';
import { parseErrorResponse, NetErrorResponse } from '../../utility/parseErrorResponse';
import { useNotification } from '../../contexts/NotificationContext';

interface MatchModalProps {
    match: MatchDto | null;
    competitionId: number;
    showFormModal: boolean;
    onSave: (match: MatchDto) => void;
    onClose: () => void;
  }
  


  const MatchModal: React.FC<MatchModalProps> = ({ match, competitionId, onSave, onClose, showFormModal }) => {
    // const { types } = useTypes();
    const [errorResponse, setErrorResponse] = useState<NetErrorResponse>({});
    const { showNotification, showModal } = useNotification();
    const [formData, setFormData] = useState<MatchDto>(
      match || {
        id: 0,
        name: '',
        startTime: null,
        result: '',
        competitionId: competitionId,
        isDeleted: false,
        lastModified: null,
        modifiedBy: null,
        typeResourceKey: 'types.Match',
      }
    );
  
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        if (e.target instanceof HTMLInputElement && type === 'checkbox') {
          const { checked } = e.target;
          setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
          }));
        } else {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      };
  
    const handleSubmit = async (e: FormEvent) => {
      e.preventDefault();
      if (formData.isDeleted && !window.confirm('Biztosan törölni akarod?')) {
        return;
      }
      try {
        await saveMatch(formData);
        onSave(formData);
        setErrorResponse({});
        showNotification('Sikeres mentés!');        
      } catch (error: any) {
        if (axios.isAxiosError(error) && error.response?.status === 500) {
          showModal(error.response.data.message);
        }
        else if(axios.isAxiosError(error) && error.response?.status === 400){
          const parsedErrors = parseErrorResponse(error as AxiosError);
          setErrorResponse(parsedErrors);
          showModal(parsedErrors.Message ?? '');
        }
        else{
          alert('An unexpected error occurred');
          console.log(error);
        }
      }
    };
  
    return (
      <>
        <Modal show={showFormModal} onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>{match ? 'Szerkeztés' : 'Új hozzáadás'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="competitionId" value={formData.competitionId} />
            <div className="mb-3">
              <label className="form-label">Típus:</label>
              <select
                name="typeResourceKey"
                value={formData.typeResourceKey}
                onChange={handleChange}
                className="form-select"
              >
                  <option key="types.Match" value="types.Match">Meccs</option>
                  <option key="types.TopScorer" value="types.TopScorer">Gólkirály</option>
                  <option key="types.Winner" value="types.Winner">Végső győztes</option>
                
              </select>
              {errorResponse.Errors?.TypeResourceKey && errorResponse.Errors.TypeResourceKey.map((error, index) => (
                  <span key={index} style={{ color: 'red' }}>{error}</span>
              ))}
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Megnevezés:</label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="form-control"
              />
              {errorResponse.Errors?.Name && errorResponse.Errors.Name.map((error, index) => (
                <span key={index} style={{ color: 'red' }}>{error}</span>
              ))}
            </div>
            <div className="mb-3">
              <label className="form-label">Időpont:</label>
              <input
                type="datetime-local"
                name="startTime"
                value={formData.startTime}
                onChange={handleChange}
                className="form-control"
              />
              {errorResponse.Errors?.StartTime && errorResponse.Errors.StartTime.map((error, index) => (
                <span key={index} style={{ color: 'red' }}>{error}</span>
              ))}
            </div>
            <div className="mb-3">
              <label htmlFor="result" className="form-label">Eredmény:</label>
              <input
                type="text"
                name="result"
                value={formData.result}
                onChange={handleChange}
                className="form-control"
              />
              {errorResponse.Errors?.Result && errorResponse.Errors.Result.map((error, index) => (
                  <span key={index} style={{ color: 'red' }}>{error}</span>
              ))}
            </div>
            <div className="mb-3">
              <label className='form-control'>
                <input
                  type="checkbox"
                  name="isDeleted"
                  checked={formData.isDeleted}
                  onChange={handleChange}
                />
                 Törlésre jelöl
              </label>
            </div>
            {errorResponse.Message && (
              <div className="mb-3">
                <div className="alert alert-danger" role="alert">
                  {errorResponse.Message}
                </div>
              </div>
            )}
            <div className="mb-3">
              <button type="submit" className='btn btn-primary'>Mentés</button><span></span>
              <button type="button" className='btn btn-secodary' onClick={onClose}>
                Mégse
              </button><span></span>
            </div>
          </form>
        </Modal.Body>
    </Modal>   
    </>
    );
  };
  
  export default MatchModal;
