import React, { useState, useEffect } from 'react';
import { CompetitionDto } from '../types';
import { getCompetitions, getCompetition, saveCompetition } from '../services/gameService';
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNotification } from '../contexts/NotificationContext';
import axios, { AxiosError } from 'axios';
import { parseErrorResponse, NetErrorResponse, Errors } from '../utility/parseErrorResponse';

const CompetitionPage: React.FC = () => {
  const [errorResponse, setErrorResponse] = useState<NetErrorResponse>({});
  const [competitions, setCompetitions] = useState<CompetitionDto[]>([]);
  const [selectedCompetition, setSelectedCompetition] = useState<CompetitionDto | null>(null);
  const [showFormModal, setShowFormModal] = useState(false);
  const { showNotification, showModal } = useNotification();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      setErrorResponse({});
      fetchCompetitions();
    } catch (error) {
      alert('An unexpected error occurred');
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }, []);

  const fetchCompetitions = async () => {
    const data = await getCompetitions();
    setCompetitions(data);
  };

  const handleEdit = async (id: number) => {
    const competition = await getCompetition(id);
    setSelectedCompetition(competition);
    setShowFormModal(true);
  };

  const handleSave = async () => {
    if (selectedCompetition) {
      if (selectedCompetition.isDeleted && !window.confirm('Biztosan törölni akarod?')) {
        return;
      }
      try {
        await saveCompetition(selectedCompetition);
        showNotification('Sikeres mentés!'); 
        setErrorResponse({});
        setShowFormModal(false);
        setSelectedCompetition(null);
      } catch (error: any) {
        if (axios.isAxiosError(error) && error.response?.status === 500) {
          showModal(error.response.data.message);
        }
        else if(axios.isAxiosError(error) && error.response?.status === 400){
          const parsedErrors = parseErrorResponse(error as AxiosError);
          setErrorResponse(parsedErrors);
          showModal(parsedErrors.Message ?? '');
        }
        else{
          alert('An unexpected error occurred');
          console.log(error);
        }
      }

      fetchCompetitions();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    if (selectedCompetition) {
      if(type =='checkbox'){
        const {checked} = e.target
        setSelectedCompetition({
          ...selectedCompetition,
          [name]: checked
        });
      }
      else{
        setSelectedCompetition({
          ...selectedCompetition,
          [name]: value
        });
      }
    }
  };

  const handleAddNew = () => {
    setErrorResponse({});
    setSelectedCompetition({
      id: 0,
      name: '',
      startDate: new Date(),
      description: '',
      isDeleted: false,
      lastModified: null,
      modifiedBy: null
    });
    setShowFormModal(true);
  };

  
  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <h2>Versenyek</h2>
      <Button variant="primary" className="mb-3" onClick={handleAddNew}>
        Új verseny
      </Button>
      <ul className="list-group">
        {competitions.map(competition => (
          <li key={competition.id} className="list-group-item">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-1">{competition.name}</h5>
              <Button variant="outline-primary" onClick={() => handleEdit(competition.id)} className='small'>
                <i className="bi bi-pencil"></i> Szerkezt
              </Button>
            </div>
            <div className="row">
              <p className="mb-1">{competition.description}</p>
            </div>
            <div className="row">
              <div className='col-sm'>
                <Link to={`/tip/${competition.id}`} className='btn btn-primary'>Tippek</Link> <span></span>
                <Link to={`/rank/${competition.id}`} className='btn btn-primary'>Ranglista</Link> <span></span>
                <Link to={`/match/${competition.id}`} className='btn btn-primary'>Eredmények</Link> <span></span>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <Modal show={showFormModal} onHide={() => setShowFormModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCompetition?.id === 0 ? 'Új verseny' : 'Verseny szerkeztése'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <input type="hidden" name="resultId" value={selectedCompetition?.startDate} />
            <Form.Group controlId="formName">
              <Form.Label>Név</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={selectedCompetition?.name || ''}
                onChange={handleChange}
              />
              {errorResponse.Errors?.Name && errorResponse.Errors.Name.map((error, index) => (
                <span key={index} style={{ color: 'red' }}>{error}</span>
              ))}
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Leírás</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={selectedCompetition?.description || ''}
                onChange={handleChange}
              />
              {errorResponse.Errors?.Description && errorResponse.Errors.Description.map((error, index) => (
                <span key={index} style={{ color: 'red' }}>{error}</span>
              ))}
            </Form.Group>
            <Form.Group controlId="formIsDeleted">
              <label className='form-control'>
                <input
                  type="checkbox"
                  name="isDeleted"
                  checked={selectedCompetition?.isDeleted}
                  onChange={handleChange}
                />
                 Törlésre jelöl
              </label>
              </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFormModal(false)}>
            Bezár
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Mentés
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompetitionPage;
