import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './MatchPage.css';
import { getMatchList, saveMatch } from '../services/gameService';
import {MatchDto} from '../types';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import { useTypes } from '../contexts/CommonContext';
import MatchModal from '../components/game/MatchModal';
import { Link } from 'react-router-dom';


const MatchPage: React.FC = () => {
  const { competitionId } = useParams<{ competitionId: string }>();
  const [matches, setMatches] = useState<MatchDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedMatch, setSelectedMatch] = useState<MatchDto | null>(null);
  const [showModal, setShowModal] = useState(false);
  
  

  useEffect(() => {  
    fetchData();
  }, [competitionId]);

  const handleEditClick = (match: MatchDto) => {
    setSelectedMatch(match);
    setShowModal(true);
  };

  const fetchData = async () => {
    try {
      const data = await getMatchList(parseInt(competitionId ?? '', 10));
      setMatches(data);
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error));
    } finally {
      setLoading(false);
    }
  };

  const handleAddClick = () => {    
    setSelectedMatch(null);
    setShowModal(true);
  };

  const handleSave = async (savedMatch: MatchDto) => {
    setMatches((prevMatches) =>
      prevMatches.some((match) => match.id === savedMatch.id)
        ? prevMatches.map((match) => (match.id === savedMatch.id ? savedMatch : match))
        : [...prevMatches, savedMatch]
    );
    setShowModal(false);
    fetchData();
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <Link to="/competition" className="breadcrumb-item">Versenyek</Link>
        <li className="breadcrumb-item active" aria-current="page">Eredmények</li>
      </ol>
    </nav>
      <h2>Eredmények</h2>
      <button onClick={handleAddClick} className='btn btn-primary'>Új hozzáadás</button> <span> </span>
      <Link to="/competition" className='btn btn-secondary'>Vissza</Link>
      <div className="container text-center">
        <div className="row">
          <div className="col">
            <h4>Megnevezés</h4> 
          </div>
          <div className="col">
            <h4>Időpont</h4>
          </div>
          <div className="col">
            <h4>Eredmény</h4>
          </div>
          <div className="col">
            
          </div>
        </div>
      {matches.map((match) => (
          <div className="row" key={match.id}>
            <div className="col">
              {match.name}
            </div>
            <div className="col">
              {format(match.startTime, 'MMMM. dd. HH:mm', { locale: hu })}
            </div>
            <div className="col">
              {match.result}
            </div>
            <div className="col">
              <button onClick={() => handleEditClick(match)} className='btn btn-primary'>Szerkezt</button>
            </div>
          </div>
        ))}
      </div>
      {showModal && (
        <MatchModal
          showFormModal = {showModal}
          match={selectedMatch}
          competitionId={parseInt(competitionId ?? '', 10)}
          onSave={handleSave}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default MatchPage;
