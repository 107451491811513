import React, { useEffect } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { Toast } from 'react-bootstrap';

const NotificationToast: React.FC = () => {
    const { notification, hideNotification } = useNotification();

    useEffect(() => {
        if (notification) {
            const timer = setTimeout(() => {
                hideNotification();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [notification, hideNotification]);

    if (!notification) {
        return null;
    }

    return (
       
        <div
            style={{
            position: 'fixed',
            bottom: 20,
            right: 20,          
            }}
        >
        <Toast delay={3000} autohide style={{backgroundColor: 'lightgreen'}}>
            <Toast.Body>{notification.message}</Toast.Body>
        </Toast>
    </div> 
    );
};

export default NotificationToast;
