import axios from 'axios';
import { CompetitionDto, MatchDto, TipDto, RankDto } from '../types';

const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL+'/api/game';

export const getCompetitions = async (): Promise<CompetitionDto[]> => {
  const response = await axios.get<CompetitionDto[]>(`${API_BASE_URL}/getCompetitions`, {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});
  return response.data;
};

export const getCompetition = async (id: number): Promise<CompetitionDto> => {
  const response = await axios.get<CompetitionDto>(`${API_BASE_URL}/getCompetition?id=${id}`, {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});
  return response.data;
};

export const saveCompetition = async (competitionDto: CompetitionDto): Promise<void> => {
  await axios.post(`${API_BASE_URL}/saveCompetition`, competitionDto, {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});
};

export const getMatchList = async (competitionId: number): Promise<MatchDto[]> => {
  const response = await axios.get<MatchDto[]>(`${API_BASE_URL}/getMatchList?competitionId=${competitionId}`, {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});
  return response.data;
};


export const saveMatch = async (matchList: MatchDto): Promise<any> => {
  const response = await axios.post(`${API_BASE_URL}/saveMatch`, matchList, {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  return response;
};

export const getTipList = async (competitionId: number): Promise<TipDto[]> => {
  const response = await axios.get<TipDto[]>(`${API_BASE_URL}/getTipList?competitionId=${competitionId}`, {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});
  return response.data;
};


export const saveTip = async (item: TipDto): Promise<any> => {
  const response = await axios.post(`${API_BASE_URL}/saveTip`, item, {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  return response;
};


export const getRankList = async (competitionId: number): Promise<RankDto[]> => {
  const response = await axios.get<RankDto[]>(`${API_BASE_URL}/getRankList?competitionId=${competitionId}`, {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});
  return response.data;
};