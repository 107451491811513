// src/components/Navbar.js
import React from 'react';
import { NavLink } from "react-router-dom";
import './Navbar.css'
import LogoutButton from '../auth/LogoutButton';
import { useAuth } from '../../contexts/AuthContext';



const Navbar: React.FC = () => {
    const { isAuthenticated, username, logout, userData } = useAuth();

    console.log('Full name: '+ userData.FullName);
    console.log(userData.FullName);
    console.log('username: '+username);

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
            <a className="navbar-brand" href="/"><img src="logo.png" className="img-fluid rounded" alt="Logó" width="50" height="50" /> Tippjáték <b><i>Béta</i></b></a>
            
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    {isAuthenticated ? (
                        <>
                        <li className="nav-item">
                            <NavLink to="/competition" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Versenyek</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/rule" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Játékszabály</NavLink>
                        </li>
                        
                        </>
                    ):(
                        <>
                        <li className="nav-item">
                            <NavLink to="/login" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Bejelentkezés</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/register" end className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Regisztráció</NavLink>
                        </li>
                        </>
                    )}
                </ul>
                <ul className="navbar-nav">
                    {isAuthenticated && (
                        <>
                            <li className="nav-item">
                                <NavLink to="/user" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><b>{userData.FullName ? userData.FullName : username}</b></NavLink>
                            </li>
                            <li className="nav-item">
                                <LogoutButton onLogout={logout} />
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </div>
    </nav>
  );
};

export default Navbar;