import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNotification } from '../../contexts/NotificationContext';

const NotificationModal: React.FC = () => {
    const { modal, hideModal  } = useNotification();

    if (!modal) {
        return null;
    }

    return (
        <Modal show={true} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Hiba</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default NotificationModal;
