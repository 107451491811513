// src/components/LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { LoginDto, UserData } from '../../types';
import { login } from '../../services/authService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useNotification } from '../../contexts/NotificationContext';
import { jwtDecode } from "jwt-decode";

const LoginForm: React.FC = () => {
    const [user, setUser] = useState<LoginDto>({ username: '', password: '' });
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { login: authLogin } = useAuth();
    const { showNotification } = useNotification();
    

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await login(user);
            localStorage.setItem('token', response.token);
            const decodedToken = jwtDecode(response.token);
            authLogin(decodedToken);
            showNotification('Sikeres bejelentkezés!');
            navigate('/'); // Navigate to home page or dashboard
        } catch (error) {
            console.log('Login failed', error);
            setError('Bejelntkezés sikertelen');
        }
    };


    return (
        <>
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Felhasználónév</label>
                <input type="text" className="form-control" name="username" value={user.username} onChange={handleChange} required />
            </div>
            <div className="form-group">
                <label>Jelszó</label>
                <input type="password" className="form-control" name="password" value={user.password} onChange={handleChange} required />
            </div>
            <br></br>
            <button type="submit" className="btn btn-primary">Bejelentkezés</button>
        </form>
        {error && <div className="alert alert-danger" role="alert">{error}</div>}
        </>
    );
};

export default LoginForm;

