import React, { useState, useEffect } from 'react';
import { UserDto } from '../types';
import { getUserDetails, updateUserDetails, changePassword } from '../services/authService';
import { useNotification } from '../contexts/NotificationContext';
import { parseErrorResponse, NetErrorResponse } from '../utility/parseErrorResponse';
import axios, { AxiosError } from 'axios';
import { useAuth } from '../contexts/AuthContext';
import ChangePasswordModal from '../components/auth/ChangePasswordModal'

const UserDetailsPage: React.FC = () => {
    const [user, setUser] = useState<UserDto>({ username: ''});
    const { showNotification, showModal } = useNotification();
    const [error, setError] = useState<string | null>(null);
    const [errorResponse, setErrorResponse] = useState<NetErrorResponse>({});
    const [loading, setLoading] = useState(true);
    const [showFormModal, setShowFormModal] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
      try {
        const data = await getUserDetails();
        setUser(data);
      } catch (error: any) {
        if (axios.isAxiosError(error) && error.response?.status === 500) {
            showModal(error.response.data.message);
        }else{
          alert('An unexpected error occurred');
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await updateUserDetails(user);
            showNotification('Sikeres mentés!');
            setErrorResponse({});
        }catch (error: any) {
            if (axios.isAxiosError(error) && error.response?.status === 500) {
              showModal(error.response.data.message);
            }
            else if(axios.isAxiosError(error) && error.response?.status === 400){
              const parsedErrors = parseErrorResponse(error as AxiosError);
              setErrorResponse(parsedErrors);
              showModal(parsedErrors.Message ?? '');
            }
            else{
              alert('An unexpected error occurred');
              console.log(error);
            }
        }            
    };

    const handlePasswordChangeClick = () => {
      setShowFormModal(true);
    };

    return (
      <>
        <h2>Felhasználói adatok</h2>
        <form onSubmit={handleSubmit}>            
          <div className="form-group">
              <label>Teljes név</label>
              <input type="text" className="form-control" name="fullName" value={user.fullName} onChange={handleChange} />
              {errorResponse.Errors?.FullName && errorResponse.Errors.FullName.map((error, index) => (
                  <span key={index} className='text-danger'>{error}</span>
              ))}
          </div>

          <br></br>
          <button type="submit" className="btn btn-primary">Mentés</button>
        </form>
        <br></br>

        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div className="accordion-body">
              <button onClick={() => handlePasswordChangeClick()} className='btn btn-link'>Jelszó változtatás</button>
              <br></br>
              {/* <button onClick={() => handleEditClick(match)} className='btn btn-link text-danger'>Felhasználó törlése</button> */}
              <br></br>
              </div>
            </div>
          </div>
        </div>

        {showFormModal && (
          <ChangePasswordModal
            onSave={() => setShowFormModal(false)}
            onClose={() => setShowFormModal(false)}
            showFormModal={showFormModal}
          />
        )}
      </>
    );
};

export default UserDetailsPage;