import axios, { AxiosError } from 'axios';

export interface Errors {
    [key: string]: string[];        
}

export interface NetErrorResponse{
    Errors?: Errors;
    Message?: string;
}

export const parseErrorResponse = (error: AxiosError): NetErrorResponse => {
    let parsedErrors: NetErrorResponse = {};


    if (error.response && error.response.data) {
        const responseData = error.response.data as NetErrorResponse;
        if (responseData) {
            parsedErrors = responseData;
            console.log(parsedErrors);
        }
    }

    return parsedErrors;
};

