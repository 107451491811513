// src/App.tsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import Navbar from './components/navbar/Navbar';
import RulePage from './pages/RulePage';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import CompetitionPage from './pages/CompetitionPage';
import MatchPage from './pages/MatchPage';
import TipPage from './pages/TipPage';
import Footer from './components/Footer';
import RankListPage from './pages/RankListPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Carousel from './components/Carousel';
import { NotificationProvider } from './contexts/NotificationContext';
import NotificationModal from './components/common/NotificationModal';
import NotificationToast from './components/common/NotificationToast';
import ErrorBoundary from './components/common/ErrorBoundary';
import UserDetailsPage from './pages/UserDetailsPage';
import './App.css';

const App: React.FC = () => {
    return (
        <ErrorBoundary>
            <AuthProvider>
                <Router>
                    <NotificationProvider>
                        <NotificationModal />
                        <NotificationToast />
                        <Navbar />
                        <div className="container">
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/register" element={<RegisterPage />} />
                                <Route path="/rule" element={<RulePage />} />
                                <Route path="/competition" element={<CompetitionPage />} />
                                <Route path="/match/:competitionId" element={<MatchPage />} />                        
                                <Route path="/tip/:competitionId" element={<TipPage />} /> 
                                <Route path="/rank/:competitionId" element={<RankListPage />} />
                                <Route path='/user' element={<UserDetailsPage />} />                                                
                            </Routes>
                        </div>
                        <Footer />
                    </NotificationProvider>
                </Router>
            </AuthProvider>
        </ErrorBoundary>
    );
};

export default App;

const Home: React.FC = () => {
    const { isAuthenticated } = useAuth();

    return (
        <>
            {!isAuthenticated && (
                <div>
                    <h2>Üdvözöllek a tippjáték oldalán!</h2>
                    <p>Játék használatához regisztráió vagy bejelentkezés szükséges</p>
                    <p></p>
                    <Carousel/>                    
                </div>
            )}
            {isAuthenticated && (
                <CompetitionPage/>
            )}
        </>
    );
};