import React, { useState, useEffect } from 'react';
import {getSystemInfo} from '../services/systemService'
import {SystemInfoDto} from '../types';

const Footer: React.FC = () => {
    const packageJson = require("../../package.json");
    const currentVersion = packageJson.version;

    const [systemInfo, setSystemInfo] = useState<SystemInfoDto>();
    useEffect(() => {
        const fetchData = async () => {
            const data = await getSystemInfo();
            setSystemInfo(data);
        }
        fetchData();
    },[]);

  return (
    <footer className="py-3 my-4">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
            <li className="nav-item"><a href="#" className="nav-link px-2 text-body-secondary">BE verzió: {systemInfo?.version}</a></li>
            <li className="nav-item"><a href="#" className="nav-link px-2 text-body-secondary">FE verzió: {currentVersion}</a></li>
        </ul>
        <p className="text-center text-body-secondary">© {new Date().getFullYear()} </p>
        
    </footer>
  );
};

export default Footer;