// src/components/LogoutButton.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface LogoutButtonProps {
    onLogout: () => void;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ onLogout }) => {
    const navigate = useNavigate();
    const handleLogout = () => {
        onLogout();
        navigate('/');
    };

    return (
        <li className="nav-item">
            <button onClick={handleLogout} className='nav-link'>Kijelentkezés</button>                       
        </li>
    );
};

export default LogoutButton;
